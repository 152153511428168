<template>
  <v-container fluid class="fill-height">
    <v-row class="fill-height">
      <v-col lg="2" class="hidden-md-and-down" />
      <v-col lg="8" align="center">
        <v-card class="fill-height">
          <v-toolbar flat>
            <v-toolbar-title>Bestellingen</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-tabs
                v-model="orderViewType"
                show-arrows
                hide-slider
                centered
                class="my-auto"
              >
                <v-tab>Van iedereen</v-tab>
                <v-divider vertical />
                <v-tab>Van mij</v-tab>
              </v-tabs>
            </v-toolbar-items>
            <v-spacer />
            <v-responsive max-width="200">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Zoeken"
                single-line
                hide-details
                clearable
                flat
                dense
              ></v-text-field>
            </v-responsive>
          </v-toolbar>
          <v-divider />
          <v-sheet class="pa-lg-4">
            <v-data-table
              :items="orderView"
              :headers="tableHeaders"
              :sort-by="sortBy"
              :search="search"
              hide-default-footer
              disable-pagination
              no-data-text
              fixed-header
            />
          </v-sheet>
        </v-card>
      </v-col>
      <v-col lg="2" class="hidden-md-and-down" />
    </v-row>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "EOrders",
  data() {
    return {
      loading: false,
      tableHeaders: [
        { text: "Datum", value: "orderDate" },
        { text: "Code", value: "productCode" },
        { text: "Product", value: "productName" },
        {
          text: "#Besteld",
          value: "orderQty",
          align: "end"
        },
        {
          text: "#Backorder",
          value: "backorderQty",
          align: "end"
        },
        { text: "Status", value: "orderStatus" },
        { text: "Besteld door", value: "orderedBy" }
      ],
      search: "",
      sortBy: ""
    };
  },
  created() {
    this.$store.dispatch("orders/fetchOrderList");
  },
  computed: {
    ...mapGetters({
      orderView: "orders/orderView"
    }),
    orderViewType: {
      get() {
        return this.$store.state.orders.orderViewType;
      },
      set(pViewType) {
        this.$store.dispatch("orders/setOrderViewType", pViewType).then(() => {
          if (pViewType === 1) {
            this.sortBy = "orderDate";
          } else {
            this.sortBy = "productCode";
          }
        });
      }
    }
  }
};
</script>
